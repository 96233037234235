var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { justify: "center", align: "center", "fill-height": "" } },
    [
      _c(
        "v-card",
        {
          staticClass: "mx-auto rounded-lg primary--text pa-8",
          attrs: { color: "white", elevation: "24" }
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "1",
                    align: "center",
                    justify: "center",
                    "align-self": "center",
                    "fill-height": ""
                  }
                },
                [
                  _c(
                    "v-row",
                    [
                      _vm.userimage
                        ? _c("v-avatar", [
                            _c("img", {
                              attrs: {
                                src:
                                  "https://randomuser.me/api/portraits/women/81.jpg"
                              }
                            })
                          ])
                        : _vm._e(),
                      !_vm.userimage
                        ? _c("v-avatar", { attrs: { color: "primary" } }, [
                            _c(
                              "span",
                              {
                                staticClass: "secondary--text font-weight-black"
                              },
                              [_vm._v(_vm._s(_vm.avaterAlphabet))]
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "11", align: "start", justify: "center" } },
                [
                  _c("v-card-title", { staticClass: "font-weight-black" }, [
                    _c("span", { staticClass: "primary--text" }, [
                      _vm._v(_vm._s(_vm.userprofile.displayName))
                    ])
                  ]),
                  _c("v-card-subtitle", { staticClass: "accent--text pb-0" }, [
                    _vm._v(_vm._s(_vm.currentUser.email))
                  ]),
                  _c(
                    "v-card-subtitle",
                    { staticClass: "accent--text py-0 my-0" },
                    [
                      _vm._v(" Last Login at "),
                      _c("b", [
                        _vm._v(
                          _vm._s(new Date(_vm.currentUser.lastLoginAt * 1000))
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-col",
                [
                  !_vm.currentUser.email
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { color: "primary", to: "/guestsignup" }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "user-plus", pull: "left" }
                          }),
                          _vm._v(" Signup")
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }